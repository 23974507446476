<template>
<div class="container">
    <b-card class="p-3">
        <h2>Add New Medicine to {{  organizationName  }} stock</h2>
        <b-form @submit.prevent="submitForm">
        <b-form-group
            label="Name:"
            label-for="name"
            description="Enter the medicine name"
        >
            <b-form-input
            id="name"
            v-model="medicine.name"
            required
            placeholder="Enter medicine name"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Composition:"
            label-for="composition"
            description="Enter the medicine composition"
        >
            <b-form-input
            id="composition"
            v-model="medicine.composition"
            required
            placeholder="Enter composition"
            ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Manufacturer:"
            label-for="manufacturer"
            description="Enter the manufacturer name"
        >
            <b-form-input
            id="manufacturer"
            v-model="medicine.manufacturer"
            required
            placeholder="Enter manufacturer"
            ></b-form-input>
        </b-form-group>

        <label for="expiry_date">Expiry Date:</label><br />
        <input type="date" id="expiry_date" v-model="medicine.expiry_date" />
        <hr>
        <b-row>
        <b-col>
            <b-form-group label="Is for Adult:">
            <b-form-checkbox v-model="medicine.is_for_adult" switch>
                {{ medicine.is_for_adult ? 'Yes' : 'No' }}
            </b-form-checkbox>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="In Stock:">
            <b-form-checkbox v-model="medicine.in_stock" switch>
                {{ medicine.in_stock ? 'Yes' : 'No' }}
            </b-form-checkbox>
            </b-form-group>
        </b-col>
        </b-row>
            <div class="text-right">
                <b-button type="submit" variant="primary" class="mt-3">Submit</b-button>
            </div>
        </b-form>
    </b-card>
</div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';

export default {
name: 'StockMedicine',

data() {
    return {
        organizationName: '',
        medicine: {
            name: '',
            composition: '',
            manufacturer: '',
            expiry_date: '',
            is_for_adult: false,
            in_stock: true
        }
    };
},
computed: {
    ...mapState({
        isLoggedIn: state => state.auth.isLoggedIn
    })
},
created() {
    console.log("inside created.", this.isLoggedIn);
    this.organizationName = this.$route.params.org || '';
    // this.checkUserAuthentication();
    console.log("Organisation name: ", this.organisationN)
},
methods: {
    ...mapActions({
        setLoggedIn: 'auth/setLoggedIn'
    }),
    async checkUserAuthentication() {
    try {
        const response = await axios.get(`${this.$store.state.backend_url}/authorization/`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        });
        console.log("User is logged in : ", response.data)
    } catch (error) {
        this.$router.push('/login');
        }
    },
    async submitForm() {
        if (this.medicine.expiry_date === "") {
            alert("Date input is empty");
        }
        else {
            try {
                const response = await axios.post(
                    `${this.$store.state.backend_url}/v1/medicine/add/?organisation_id=1`,
                    this.medicine,
                    { withCredentials: true }
                );
                this.$bvToast.toast('Medicine added successfully', {
                title: 'Success',
                variant: 'success',
                solid: true
                });
                this.resetForm();
            } catch (error) {
                console.log(error);
                if (error.response && error.response.status === 401) {
                this.storeFormDataInCookie();
                this.$router.push('/login');
                } else {
                this.$bvToast.toast('Error adding medicine', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
            });
        }
    }
        }

    },
    resetForm() {
    this.medicine = {
        name: '',
        composition: '',
        manufacturer: '',
        expiry_date: null,
        is_for_adult: false,
        in_stock: true
    };
    },
    storeFormDataInCookie() {
    document.cookie = `medicineFormData=${JSON.stringify(this.medicine)}; path=/`;
    }
}
};
</script>

<style scoped>
.medicine-form {
max-width: 500px;
margin: 0 auto;
padding: 20px;
}

.custom-datepicker .b-form-datepicker-inner {
    background-color: rgba(200, 200, 200, 0.8) !important;
}

.custom-datepicker .b-calendar-grid-body .btn-light {
    background-color: transparent !important;
    color: #000 !important;
}

.custom-datepicker .b-calendar-grid-body .btn-light:hover,
.custom-datepicker .b-calendar-grid-body .btn-light:focus {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.custom-datepicker .b-calendar-grid-body .btn-primary {
    background-color: #007bff !important;
    color: #fff !important;
}

.text-right {
    text-align: right;
}
</style>