import Vue from "vue"
import Vuex from "vuex"
import axios from 'axios'

Vue.use(Vuex);

const state = {
    backend_url: process.env.VUE_APP_BACKEND_URL || 'http://localhost:8091/v1',
    auth: {
        isLoggedIn: false,
        userEmail: ''
    },
    subscriptionPlans: [],
    organisations: [],
    visitorIdentifier: ''
}

const getters = {
    getBackendUrl: state => state.backend_url,
    isLoggedIn: state => state.auth.isLoggedIn,
    userEmail: state => state.auth.userEmail,
    subscriptionPlans: state => state.subscriptionPlans,
    organisations: state => state.organisations,
    currentError: state => state.error,
};

const actions = {
    getCookie(context, name) {
        const cookieArr = document.cookie.split(';');
        for (let cookie of cookieArr) {
            let [cookieName, cookieValue] = cookie.split('=');
            cookieName = cookieName.trim();
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
        return '';
    },
    logoutAction({ commit }) {
        commit('SET_LOGOUT');
    },
    async checkAuthentication({ commit, dispatch }) {
    try {
        const response = await axios.get(`${state.backend_url}/authorization/`, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        });
        const authenticatedUser = response.data["user"];
        const registrationEmail = await dispatch('getCookie', 'registrationEmail');
        if (authenticatedUser && authenticatedUser === registrationEmail) {
            commit('SET_AUTH', { isLoggedIn: true, userEmail: authenticatedUser });
            return true;
        }
        } catch (error) {
        console.error('Authentication check failed:', error);
        commit('SET_AUTH', { isLoggedIn: false, userEmail: '' });
        }
    },
    async fetchOrganisations( {commit} ) {
        this.loading = true;
        this.error = null;
        try {
                const resp = await axios.get(`${state.backend_url}/organisation/user/`, {withCredentials: true});
                console.log(resp.data)
                commit('SET_EXISTING_ORGS', resp.data)
            } catch (err) {
                console.error('Error fetching organizations:', err);
                commit('SET_EXISTING_ORGS', [])
                this.error = 'Failed to fetch organizations. Please try again.';
        } finally {
            this.loading = false;
        }
    },
    async fetchSubscriptionPlans({ commit, state }) {
        try {
                const response = await axios.get(`${state.backend_url}/v1/organisation/subscription/plans`);
                commit('SET_SUBSCRIPTION_PLANS', response.data);
            } catch (error) {
            console.error('Failed to fetch subscription plans:', error);
            }
        },
    async addOrganisation({ commit, state }, organisationData) {
        try {
            const response = await axios.post(`${state.backend_url}/v1/organisation/add/`, organisationData);
            commit('ADD_ORGANISATION', response.data);
            return response.data;
        } catch (error) {
            console.error('Failed to add organisation:', error);
            throw error;
        }
    },
};

const mutations = {
    SET_AUTH(state, { isLoggedIn, userEmail }) {
    state.auth.isLoggedIn = isLoggedIn;
    state.auth.userEmail = userEmail;
    },
    SET_LOGOUT(state) {
        state.isLoggedIn = false;
        state.userEmail = '';
        state.organisations = [];
    },
    SET_EXISTING_ORGS(state, organisations){
        state.organisations = organisations;
    },
    SET_SUBSCRIPTION_PLANS(state, plans) {
    state.subscriptionPlans = plans;
    },
    ADD_ORGANISATION(state, organisation) {
    state.organisations.push(organisation);
    }
};


// store configurations object is passed to Vuex.Store
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
