<template>
    <div class="container">
    <h2>Welcome</h2>
    <hr>
    <div v-if="isLoggedIn">
        <b-tabs pills card vertical v-if="organisations.length">
            <b-tab 
                v-for="(org, index) in organisations" 
                :key="org.id" 
                :title="org.name"
                :active="index === 0"
                >
                <b-card-text>
                <h5>{{ org.category }}</h5>
                <ul>
                    <li>Add Staff Members</li>
                    <li><a href="#" @click.prevent="goToMedicine(org.name)">Add Medicine</a></li>
                </ul>
                </b-card-text>
            </b-tab>
            <b-tab title="Add New" @click="addNewOrganisation">
                <b-card-text>
                <a href="#" @click.prevent="addNewOrganisation">Click to add a new organisation</a>
                </b-card-text>
            </b-tab>
        </b-tabs>

        <div v-else-if="!loading">
            <p> {{ this.organisations_message }}</p>
            <p>Would you like to add one?</p>
            <button @click="goToAddOrganisation" class="add-org-button">
                Add Organisation
            </button>
        </div>

        <div class="user-info" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
            <div class="user-circle" @click="toggleDropdown">
                {{ userEmail.charAt(0).toUpperCase() }}
            </div>
            <span class="dropdown-arrow">▼</span>
                <div class="dropdown-menu" v-show="showDropdown">
                    <p>{{ userEmail }}</p>
                    <a href="#" @click.prevent="logout">Logout</a>
                </div>
            </div>
        </div>
    <div v-else>
        <p class="text-danger">Not authenticated. Please login again.</p>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    name: 'WelcomeUser',
    computed: {
        ...mapGetters(
            [
                'isLoggedIn',
                'userEmail',
                'organisations',
            ]
        )
        },
    data() {
        return {
            error: null,
            loading: false,
            showDropdown: false,
            organisations_message: '',
        };
    },
created() {
if (this.isLoggedIn) {
        this.$store.dispatch('fetchOrganisations');  // Fetch organisations via Vuex action
    }
},
methods: {
    goToAddOrganisation() {
        this.$router.push('/add-organisation');
    },
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    },
    addNewOrganisation() {
        this.$router.push('/add-organisation');
    },
    goToMedicine(orgName) {
        this.$router.push({
            name: 'StockMedicine',
            params: { org: orgName }
        });
        console.log("Katooo", orgName);
    },
    removeEmailCookie() {
    localStorage.removeItem('registrationEmail');
    },
    async logout() {
        try {
        await axios.post(`${this.$store.state.backend_url}/authorization/logout`, {}, {
            withCredentials: true
        });
        // Call Vuex action to update state
        this.$store.dispatch('logoutAction');

        this.removeEmailCookie();
        this.$router.push('/login');
        } catch (error) {
        console.error('Logout failed:', error);
        }
    },
async checkAuth() {
    try {
    await this.$store.dispatch('checkAuthentication');
    await this.$store.dispatch('fetchOrganisations');
    // The auth state will be updated automatically if the check is successful
    } catch (error) {
    console.error('Error checking authentication:', error);
    // TODO: in messagebox show an error message to the user
    }
}
},
mounted() {
    // check authentication when the component mounts
    this.checkAuth();
    }
};
</script>

<style scoped>
.container {
    margin-top: 50px;
    position: relative;
}

.user-info {
    position: absolute;
    top: 0.5px; /* Adjust as necessary */
    right: 10px; /* Adjust as necessary */
    display: flex;
    cursor: hand;
}
.no-org-container {
    position: relative;
    min-height: 100px;
}
.add-org-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgb(41, 111, 243);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}
/* Circle with first letter */
.user-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(41, 111, 243);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

/* Dropdown arrow next to the circle */
.dropdown-arrow {
    margin-left: 10px;
    font-size: 16px;
    color: #333;
}

/* Dropdown menu hidden by default */
.dropdown-menu {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    display: none;
}

  /* Show dropdown when v-show is true */
.user-info .dropdown-menu{
    display: block;
}

  /* Style for email and logout link */
.dropdown-menu p {
    margin: 0;
    font-weight: bold;
    color: #333;
}

.dropdown-menu a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
}

.dropdown-menu a:hover {
color: #0056b3;
text-decoration: underline;
}
</style>
