<template>
    <div v-if="visible" class="message-box">
    <p>{{ message }}</p>
    <button @click="close">Close</button>
    </div>
</template>

<script>
export default {
props: {
    message: String,
    duration: {
        type: Number,
        default: 5000 // Default duration of 3 seconds
    }
},
data() {
    return {
    visible: false
    };
},
methods: {
    show() {
    this.visible = true;
    setTimeout(() => {
        this.visible = false;
    }, this.duration);
    },
    close() {
        this.visible = false;
    }
}
};
</script>

<style>
.message-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.message-box button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.message-box button:hover {
    background-color: #0056b3;
}
</style>
